import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { userHasDevSettingAccess } from 'containers/DevSettings/utils/helpers';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const SidebarContent = ({ onClick }) => {
	const hideSidebar = () => {
		onClick();
	};

	const { loggedInUser } = useSelector((state) => ({
		loggedInUser: state.auth.user,
	}));

	return (
		<div className="sidebar__content">
			<ul className="sidebar__block">
				<SidebarLink title="Users" route="/users/list" icon="users" onClick={hideSidebar} />
				<SidebarLink title="FAQ" route="/faq/list" icon="question-circle" onClick={hideSidebar} />
				{/* <SidebarCategory title="Curriculum & Exercises" icon="book">
					<SidebarLink title="Curriculum" route="/exercise/curriculum/list" icon="menu" onClick={hideSidebar} />
					<SidebarLink title="Questions" route="/exercise/questions/list" icon="menu" onClick={hideSidebar} />
					<SidebarLink title="Courses" route="/exercise/courses/list" icon="menu" onClick={hideSidebar} />
				</SidebarCategory> */}
				<SidebarCategory title="Lessons" icon="book">
					<SidebarLink title="Lessons" route="/lessons/list" onClick={hideSidebar} />
				</SidebarCategory>
				<SidebarCategory title="Dev Settings" icon="picture">
					<SidebarLink title="S3 File Upload" route="/dev-settings/s3-file-upload" onClick={hideSidebar} />
					<SidebarLink title="Payment Services" route="/dev-settings/payment-services" onClick={hideSidebar} />
					{userHasDevSettingAccess(loggedInUser.email[0]) && (
						<>
							<SidebarLink title="Redis CLI" route="/dev-settings/redis-cli" onClick={hideSidebar} />
							<SidebarLink title="Cloudfront Cache Invalidate" route="/dev-settings/cf-cache-invalidate" onClick={hideSidebar} />
						</>
					)}
				</SidebarCategory>
			</ul>
		</div>
	);
};

export default SidebarContent;

SidebarContent.propTypes = {
	onClick: PropTypes.func.isRequired,
};
