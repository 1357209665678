import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LessonsList from './components/LessonsList';
import { LessonAddEditForm } from './components/LessonAddEditForm';
import LessonQuestionList from './components/LessonQuestionList';
import LessonQuestionAddEditForm from './components/LessonQuestionAddEditForm';

const Lessons = () => {
	return (
		<div>
			<Switch>
				<Route path="/lessons/list" component={LessonsList} />
				<Route path="/lessons/add-edit" component={LessonAddEditForm} />
				<Route path="/lessons/questions/list" component={LessonQuestionList} />
				<Route path="/lessons/questions/add-edit" component={LessonQuestionAddEditForm} />
			</Switch>
		</div>
	);
};
export default Lessons;
