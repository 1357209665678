import React from 'react';
import { showToast } from 'utils/commonHelpers';
import style from './styles.module.scss';
import { QuestionTypesEnum } from './types';

export const questionDisplayFormatter = (question: any) => {
	const questionTitle = question?.title?.en?.text;
	try {
		switch (question.type) {
			case QuestionTypesEnum.MCQ:
				return formatMCQQuestion(question);
			case QuestionTypesEnum.CONVERSATION:
			case QuestionTypesEnum.CONTENT_ONLY:
			case QuestionTypesEnum.READ_ALONG:
				return (
					<>
						<p className={style.questionMainTitle}>{questionTitle}</p>
					</>
				);
			default:
				return <p className={style.questionMainTitle}>{questionTitle}</p>;
		}
	} catch (error) {
		showToast('error', error.message);
		return questionTitle;
	}
};

const formatMCQQuestion = (question) => {
	return (
		<>
			<p className={style.questionMainTitle}>{question?.title?.en?.text}</p>

			<br />
			<div className={style.flexContainer}>
				{question.options.map((option: any) => {
					return (
						<div
							style={{
								backgroundColor: option.isCorrectAnswer ? '#dfffc2' : '#fff',
							}}
							className={style.optionContainer}
							key={option.text.en.text}
						>
							<p>{option.text.en.text}</p>
						</div>
					);
				})}
			</div>
		</>
	);
};
