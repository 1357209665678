import { reducer as reduxFormReducer } from 'redux-form';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import sidebarReducer from '../../redux/reducers/sidebarReducer';
import modalReducer from '../../connectors/ModalController/reducer';
import authReducer from '../Authentication/authSlice';
import faqReducer from '../FAQ/faqSlice';
import userReducer from '../Users/userSlice';
import classReducer from '../Class/classSlice';
import programReducer from '../Program/programSlice';
import devSettingsReducer from '../DevSettings/slice';
import globalReducer from './globalSlice';
import membershipReducer from '../Membership/membershipSlice';
import ExercisesReducer from '../Exercise/ExercisesSlice';
import LessonsReducer from '../Lessons/LessonsSlice';

import allSagas from '../../redux/allSagas';

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
	sidebar: sidebarReducer,
	form: reduxFormReducer,
	modals: modalReducer,
	auth: authReducer,
	faq: faqReducer,
	classes: classReducer,
	program: programReducer,
	devSettings: devSettingsReducer,
	user: userReducer,
	global: globalReducer,
	membership: membershipReducer,
	exercises: ExercisesReducer,
	lessons: LessonsReducer,
});

const store = configureStore({
	reducer,
	middleware: [sagaMiddleware, thunkMiddleware],
	devTools: true,
});

allSagas.forEach((x) => sagaMiddleware.run(x));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
