/* eslint-disable no-lonely-if */
import React, { useState } from 'react';
import { isEmptyArray } from 'formik';
import { useEffect } from 'react';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { actionSpreader, isNotEmptyObject, parseQueryParams, showToast, stringifyQueryParams } from 'utils/commonHelpers';
import { SelectFieldNew } from 'reusableComponents/HookForm/Select';
import { getPreviewImages, uploadImagesAndConvertToDatabaseFormat } from 'utils/hookFormImageHelpers';
import { gptModelOptions, roleVoiceOptions } from 'utils/constants';
import { fetchWrapper } from 'utils/fetchWrapper';
import Box from 'reusableComponents/Box';
import { DropzoneNew } from 'reusableComponents/HookForm/Dropzone';
import { InputNew } from 'reusableComponents/HookForm/Input';
import { TextAreaNew } from 'reusableComponents/HookForm/TextArea';
import WordMatrix from 'containers/Exercise/components/Grid/wordMatrix';
import LoadingOverlay from 'reusableComponents/LoadingOverlay';
import { Checkbox } from 'reusableComponents/HookForm/Checkbox';
import { setBreadcrumbs } from 'containers/App/globalSlice';
import Breadcrumbs from 'components/Breadcrumbs';
import { useNavigation } from 'context/navigationContext';
import { setIsLoading } from '../LessonsSlice';
import { validateImage } from '../utils/validateImage';
import { fetchLesson, fetchLessonQuestion, updateLessonQuestion } from '../actions/lessons.actions';
import { QuestionTypeFields } from '../utils/helpers';
import {
	MediaTypeEnum,
	QuestionTypesEnum,
	UiTypeEnum,
	TQuestion,
	TMcqLessonQuestion,
	TReadAlongLessonQuestion,
	TContentOnlyLessonQuestion,
	TConversationLessonQuestion,
} from '../utils/types';
import AudioPlayer from './audioPlayerQuestion';

const LessonQuestionAddEditForm = (props) => {
	const dispatch = useDispatch();
	const { navigate } = useNavigation();
	const { register, handleSubmit, watch, errors, setValue, control, ...hookFormMethods } = useForm();
	const { questionId, mode, curriculumId } = parseQueryParams(props.location.search) ?? {};
	const { lessonId } = parseQueryParams(props.location.search) ?? {};
	const {
		lessons: { questions, isLoading, lesson },
		userId,
	} = useSelector(({ lessons, auth }) => ({ lessons, userId: auth?.user?.id }));

	// audio related data
	const questionReduxData = useSelector(({ lessons }) => {
		return lessons?.questions?.[0];
	});

	const audioData = {
		title: questionReduxData?.title?.en?.audio,
		completeSentence: (questionReduxData as TReadAlongLessonQuestion)?.completeSentence?.en?.audio,
		meaning: (questionReduxData as TContentOnlyLessonQuestion)?.meaning?.en?.audio,
		scene: (questionReduxData as TConversationLessonQuestion)?.scene?.en?.audio,
		firstBotDialogue: (questionReduxData as TConversationLessonQuestion)?.firstBotDialogue?.en?.audio,
		options: (questionReduxData as TMcqLessonQuestion)?.options?.map((option) => option?.text?.en?.audio),
	};

	const breadcrumbsData = useSelector(({ global }) => ({
		breadcrumbs: global.breadcrumbs,
	}));

	const { uiType } = (lesson ?? {}) as TQuestion & {
		uiType: UiTypeEnum;
	};
	const question = questions?.find((question) => question?.id === questionId);

	const [areImagesValid, setAreImagesValid] = useState(true); // to keep track of whether all images are valid
	const questionType = watch('type');
	const mcqWatch = watch(['options', 'title']);
	const questionMapping = watch('questionMapping');

	const optionsFieldArray = useFieldArray({
		control,
		name: 'options',
	});

	useEffect(() => {
		checkImageInMcq();
	}, [mcqWatch.options]);

	const descriptionImageText = watch('descriptionImage');
	const [invalidWordsData, setInvalidWordsData] = useState([]);
	const [wordsMeaningSentences, setWordsMeaningSentences] = useState([]);
	useEffect(() => {
		const minimumAspectRatio = 1.76;
		const maximumAspectRatio = 2.05;

		(async () => {
			if (!descriptionImageText) {
				return;
			}

			const flag = await validateImage(descriptionImageText, {
				minimumAspectRatio,
				maximumAspectRatio,
			});

			if (flag) {
				setAreImagesValid(true);
			} else {
				setAreImagesValid(false);
				showToast('error', 'Image is of wrong dimension');
			}
		})();
	}, [descriptionImageText]);

	useEffect(() => {
		dispatch(
			actionSpreader(setBreadcrumbs.type, {
				breadcrumbs: [
					{
						text: 'Lesson',
						url: `/lessons/list${stringifyQueryParams({})}`,
					},
					{
						text: 'Questions',
						url: `/lessons/questions/list${stringifyQueryParams({ lessonId })}`,
					},
					{
						text: 'Question form',
						url: `/lessons/questions/add-edit${stringifyQueryParams({ lessonId, mode, questionId })}`,
					},
				],
			})
		);

		if (isNotEmptyObject(question)) {
			const options =
				question.type === QuestionTypesEnum.MCQ
					? question.options?.map((option, index) => {
							return {
								isCorrectAnswer: option.isCorrectAnswer,
								value: option.text,
								image:
									option?.media?.mediaPath && getPreviewImages({ id: question.id, mediaType: 'image', url: option.media.mediaPath }, false),
							};
					  })
					: [];

			const responseForForm = {
				...question,
				...(question.title?.en?.text && {
					title: question.title?.en?.text,
				}),
				...(question.subtitle?.en?.text && {
					subtitle: question.subtitle?.en?.text,
				}),
				...(question.type === QuestionTypesEnum.CONTENT_ONLY &&
					question.meaning?.en?.text && {
						meaning: question.meaning?.en?.text,
					}),
				...(question.type === QuestionTypesEnum.CONVERSATION && {
					scene: question.scene?.en?.text,
					firstBotDialogue: question.firstBotDialogue?.en?.text,
					firstBotDialogueHint: question.firstBotDialogueHint?.en?.text,
					...(question.systemVoiceId && { systemVoiceId: roleVoiceOptions.find((option) => option.value === question.systemVoiceId) }),
					...(question.botVoiceId && { botVoiceId: roleVoiceOptions.find((option) => option.value === question.botVoiceId) }),
					...(question.gptModel && { gptModel: gptModelOptions.find((option) => option.value === question.gptModel) }),
				}),
				...(question.type === QuestionTypesEnum.READ_ALONG && {
					voiceId: roleVoiceOptions.find((option) => option.value === question.voiceId),
					completeSentence: question.completeSentence?.en?.text,
				}),
				...(question?.voiceId && {
					roleVoiceId: roleVoiceOptions.find((option) => option.value === question?.voiceId),
				}),
				descriptionImage: question.descriptionImage?.mediaPath
					? getPreviewImages({ id: question.id, mediaType: 'image', url: question.descriptionImage?.mediaPath }, false)
					: null,
				options,
				type: QuestionTypeFields.find((type) => type.value === question.type),
				uiType,
			};
			hookFormMethods.reset(responseForForm);
		} else {
			dispatch(fetchLesson({ lessonId }));
			if (mode !== 'add') {
				dispatch(fetchLessonQuestion({ questionId }));
			}
		}
	}, [question, mode, questionId]);

	const onSubmit = async (data) => {
		if (!data.type) {
			return showToast('error', 'Please select question type');
		}

		if (data.type.value === QuestionTypesEnum.MCQ) {
			// remove the boxes options that are not filled
			data.options = data.options.filter((option) => option.value || option.media);

			if (data?.options?.findIndex((x) => x.isCorrectAnswer) === -1) {
				return showToast('error', 'An MCQ Question should have atleast 1 correct option.');
			}
			if (data?.options?.length < 2) {
				return showToast('error', 'Please enter atleast 2 options with text or image not both');
			}
		} else if (data.type.value === QuestionTypesEnum.MEDIA) {
			if (!data?.allowedMediaTypes?.length) {
				return showToast('error', 'Please select atleast one media type');
			}
		}

		// validate all images
		const mcqImageFlag = await checkImageInMcq();
		if (!mcqImageFlag) return;

		dispatch(setIsLoading());
		const entityType = 'EXERCISE_QUESTION';

		const optionsWithImages = [];

		if (data.type.value === QuestionTypesEnum.MCQ) {
			for (let i = 0; i < data.options.length; i += 1) {
				if (data.options[i].media) {
					optionsWithImages.push({
						key: `options_${i}_image`,
						isMulti: false,
						withCaption: false,
						entityId: questionId ?? lessonId, // in add form, questionId is not available, so we send lessonId as entityId
						entityType,
						image: data.options[i].media,
					});
					data[`options_${i}_image`] = data.options[i].media;
				}
			}
		}

		const imagesKeys = [
			{ key: 'descriptionImage', isMulti: false, withCaption: false, entityId: questionId ?? lessonId, entityType },
			...optionsWithImages,
		];

		const imagesUploadData: any = await uploadImagesAndConvertToDatabaseFormat({ imagesKeys, data });

		if (data.type.value === QuestionTypesEnum.CROSSWORD) {
			// Marking invalid words as invalid
			const promises = [];
			if (!isEmptyArray(invalidWordsData)) {
				promises.concat(
					invalidWordsData.map((invalidWord) => {
						const { wordId, wordText } = invalidWord;
						return fetchWrapper(`/v1/admin/mark-word-as-invalid`, {
							method: 'POST',
							body: { id: wordId, text: wordText },
						});
					})
				);
			}

			await Promise.all(promises);

			if (!isEmptyArray(wordsMeaningSentences)) {
				try {
					const response = await fetchWrapper(`/admin/v1/set-words-meaning-and-example`, {
						method: 'POST',
						body: { words: wordsMeaningSentences },
					});

					if (response.data.status === true) {
						showToast('success', `Word, meanings and examples were updated ✅`);
					} else {
						showToast('error', `Failed to updated word, meanings and examples 🚨`);
					}
				} catch (err) {
					console.log('error in updating word meaning and sentences', err);
				}
			}
		}

		dispatch(
			updateLessonQuestion({
				userId,
				questionId,
				lessonId,
				updatedQuestion: ({
					...question,
					...(data.difficulty?.value && { difficulty: data.difficulty.value }),
					uiType,
					...((question?.type === QuestionTypesEnum.MCQ || data?.type?.value === QuestionTypesEnum.MCQ) && {
						imageDescription: data.imageDescription,
					}),
					...((question?.type === QuestionTypesEnum.CONTENT_ONLY || data?.type?.value === QuestionTypesEnum.CONTENT_ONLY) && {
						roleName: data?.roleName?.trim(),
					}),
					voiceId: data.roleVoiceId?.value,
					title: {
						...(question?.title && question.title),
						en: {
							...question?.title?.en,
							text: data.title?.trim(),
						},
					},
					subtitle: {
						...(question?.subtitle && question.subtitle),
						en: {
							...question?.subtitle?.en,
							text: data.subtitle?.trim(),
						},
					},
					...(data?.type?.value === QuestionTypesEnum.READ_ALONG && {
						completeSentence: {
							en: {
								...(question as TReadAlongLessonQuestion)?.completeSentence?.en,
								text: data.completeSentence?.trim(),
							},
						},
					}),
					...(imagesUploadData?.descriptionImage?.url
						? {
								descriptionImage: {
									mediaId: imagesUploadData?.descriptionImage?.id,
									mediaPath: imagesUploadData?.descriptionImage?.url,
									mediaType: MediaTypeEnum.IMAGE,
								},
						  }
						: { descriptionImage: null }),
					options: data.options?.map((option, index) => {
						return {
							_id: question?.type === QuestionTypesEnum.MCQ ? question?.options?.[index]?.['_id'] : undefined,
							text: {
								...(question as TMcqLessonQuestion)?.options[index]?.text,
								en: {
									...(question as TMcqLessonQuestion)?.options[index]?.text?.en,
									text: option.value?.en?.text?.trim(),
								},
							},
							isCorrectAnswer: option.isCorrectAnswer,
							...(imagesUploadData?.[`options_${index}_image`]?.url
								? {
										media: {
											mediaId: imagesUploadData?.[`options_${index}_image`]?.id,
											mediaType: MediaTypeEnum.IMAGE,
											mediaPath: imagesUploadData?.[`options_${index}_image`]?.url,
										},
								  }
								: {}),
						};
					}),
					type: data.type.value,
					...(data.type.value === QuestionTypesEnum.CONVERSATION && {
						scene: {
							en: {
								...data.scene?.en,
								text: data.scene?.trim(),
							},
						},
						systemPrompt: data.systemPrompt?.trim(),
						userPrompt: data.userPrompt?.trim(),
						botName: data.botName?.trim(),
						firstBotDialogue: {
							en: {
								...data?.firstBoDialogue?.en,
								text: data.firstBotDialogue?.trim(),
							},
						},
						firstBotDialogueHint: {
							en: {
								...data?.firstBoDialogue?.en,
								text: data.firstBotDialogueHint?.trim(),
							},
						},
						systemVoiceId: data?.systemVoiceId?.value,
						botVoiceId: data?.botVoiceId?.value,
						voiceId: data.voiceId?.value ?? roleVoiceOptions[0].value,
						gptModel: data.gptModel.value,
						maxLength: data.maxLength,
					}),
					...(data.type.value === QuestionTypesEnum.READ_ALONG && {
						voiceId: data.voiceId?.value ?? roleVoiceOptions[0].value,
					}),
					...(data.type.value === QuestionTypesEnum.MCQ && {
						voiceId: data.voiceId?.value ?? roleVoiceOptions[0].value,
					}),
					...(data.type.value === QuestionTypesEnum.CROSSWORD && {
						title: {
							en: {
								text: 'Crossword',
							},
						},
						subtitle: {
							en: {
								text: 'Crossword',
							},
						},
						letterGrid: data.letterGrid,
						level: data.level,
					}),
					...(data.meaning && {
						meaning: {
							en: {
								text: data.meaning,
							},
						},
					}),
					...(data.partsOfSpeech && {
						partsOfSpeech: data.partsOfSpeech,
					}),
				} as unknown) as Partial<TQuestion>,
				mode,
				navigate,
			})
		);
	};

	// check if any of the images in mcq type question of wrong dimension
	const checkImageInMcq = async () => {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			if (mcqWatch.options) {
				for (let idx = 0; idx < mcqWatch.options.length; idx += 1) {
					const option = mcqWatch.options[idx];
					// eslint-disable-next-line no-await-in-loop
					const flag = await validateImage(option.media, {
						minimumAspectRatio: 1,
						maximumAspectRatio: 1,
					});

					if (!flag) {
						resolve(false);
						showToast('error', `Image no ${idx + 1} is of wrong dimensions`);
					}
				}
			}

			resolve(true);
		});
	};

	const getQuestionForm = (question: TQuestion) => {
		if (!question) return;
		switch (question.type) {
			case QuestionTypesEnum.CONVERSATION:
				return (
					<>
						<Row>
							<Col sm="11">
								<TextAreaNew name="title" label="Title" required defaultValue="" />
							</Col>
							<Col sm="1">
								<AudioPlayer audioData={audioData?.title?.[question?.voiceId]} text={question?.voiceId} />
							</Col>
						</Row>
						<Row>
							<Col sm="11">
								<TextAreaNew name="scene" label="Scene" defaultValue="" required />
							</Col>
							<Col sm="1">
								<AudioPlayer audioData={audioData?.scene?.[question?.systemVoiceId]} text={question?.systemVoiceId} />
							</Col>
							<Col sm="12">
								<SelectFieldNew label="Scene Voice" name="systemVoiceId" options={roleVoiceOptions} defaultValue={roleVoiceOptions[0]} />
							</Col>
						</Row>
						<Row>
							<Col>
								<TextAreaNew name="systemPrompt" label="System Prompt" required defaultValue="" />
							</Col>
						</Row>
						<Row>
							<Col>
								<TextAreaNew name="userPrompt" label="User Prompts" required defaultValue="" />
							</Col>
						</Row>
						<Row>
							<Col sm="12">
								<SelectFieldNew label="Bot Voice" name="botVoiceId" options={roleVoiceOptions} defaultValue={roleVoiceOptions[0]} />
							</Col>
							<Col>
								<InputNew name="botName" label="AI Role Name" required defaultValue="" />
							</Col>
						</Row>
						<Row>
							<Col sm="11">
								<TextAreaNew name="firstBotDialogue" label="Initial Dialogue for AI Role" required defaultValue="" />
							</Col>
							<Col sm="1">
								<AudioPlayer audioData={audioData?.firstBotDialogue?.[question?.botVoiceId]} text={question?.botVoiceId} />
							</Col>
						</Row>
						<Row>
							<Col>
								<TextAreaNew name="firstBotDialogueHint" label="Hint for the user of the Initial Dialogue" required defaultValue="" />
							</Col>
						</Row>
						<Row>
							<Col>
								<InputNew
									type="number"
									name="maxLength"
									label="Max Conversation Length(End conversation after this)"
									required
									defaultValue={9999}
									readOnly
									max={9999}
									min={1}
								/>
							</Col>
						</Row>
						<Row>
							<Col sm="12">
								<SelectFieldNew label="Chatgpt Model" name="gptModel" options={gptModelOptions} defaultValue={gptModelOptions[0]} />
							</Col>
						</Row>
					</>
				);

			case QuestionTypesEnum.MCQ:
				return (
					<>
						<Row>
							<Col sm="8">
								<Row>
									<Col sm="11">
										<InputNew name="title" label="Question Text" defaultValue="" placeholder="Question Text" />
									</Col>
									<Col sm="1">
										<AudioPlayer audioData={audioData?.title?.[question?.voiceId]} text={question?.voiceId} />
									</Col>
								</Row>
								<Row>
									<Col>
										<DropzoneNew label="Associated Image: (Range of Aspect ratio 1.95 to 2.05)" name="descriptionImage" />
									</Col>
								</Row>

								<Row>
									{optionsFieldArray.fields.map((option, idx) => {
										return (
											<Box
												key={option.id || idx}
												pos="relative"
												style={{
													width: '50%',
												}}
											>
												<Row style={{ border: '1px solid #bababa', padding: '20px 0', margin: '10px 0', position: 'relative' }}>
													<Col sm="12">
														{option.image && (
															<>
																<DropzoneNew label="Upload Image (150X150)px" name={`options[${idx}].media`} defaultValue={option.image} />
															</>
														)}
														<Checkbox label="Is Correct?" name={`options[${idx}].isCorrectAnswer`} checked={option?.isCorrectAnswer} />
														<Row>
															<Col sm="10">
																<InputNew
																	maxLength={80}
																	name={`options[${idx}].value.en.text`}
																	defaultValue={option?.value?.en?.text}
																	label="Image Text"
																	placeholder="Image Text"
																/>
															</Col>
															<Col sm="2">
																<AudioPlayer audioData={audioData?.options?.[idx]?.[question?.voiceId]} text={question?.voiceId} />
															</Col>
														</Row>
													</Col>
													<Box onClick={() => optionsFieldArray.remove(idx)} cursor="pointer" pos="absolute" t="6px" r="6px">
														<span className="lnr lnr-cross" />
													</Box>
												</Row>
											</Box>
										);
									})}
								</Row>

								<Row
									style={{
										justifyContent: 'center',
									}}
								>
									<Button
										disabled={optionsFieldArray.fields.length >= 4}
										type="button"
										onClick={() => {
											optionsFieldArray.append({});
										}}
									>
										+ Add New Option
									</Button>
								</Row>
							</Col>
						</Row>
					</>
				);
			case QuestionTypesEnum.READ_ALONG:
				return (
					<>
						<Row className="d-flex">
							<Col className=".col-xs-6">
								<Col sm="12">
									<Row>
										<Col sm="11">
											<TextAreaNew name="completeSentence" label="Complete Sentence:" placeholder="Enter the full sentence" />
										</Col>
										<Col sm="1">
											<AudioPlayer audioData={audioData?.completeSentence?.[question?.voiceId]} text={question?.voiceId} />
										</Col>
									</Row>
								</Col>
								<Col sm="12">
									<Row>
										<Col sm="11">
											<TextAreaNew
												maxLength={200}
												required
												name="title"
												label="Text to Read:"
												placeholder="Enter the primary text that the user will read aloud."
											/>
										</Col>
										<Col sm="1">
											<AudioPlayer audioData={audioData?.title?.[question?.voiceId]} text={question?.voiceId} />
										</Col>
									</Row>
								</Col>
								<Col sm="12">
									<DropzoneNew
										height="600"
										width="900"
										label="Associated Image: (Range of Aspect ratio 1.95 to 2.05)"
										name="descriptionImage"
									/>
								</Col>
							</Col>
						</Row>
					</>
				);
			case QuestionTypesEnum.CROSSWORD:
				return (
					<>
						<Row className="d-flex">
							<Col className=".col-xs-12">
								<Col sm="12">
									<InputNew label="Level" name="level" required />
								</Col>
							</Col>
						</Row>
						<Row className="d-flex">
							<Col className=".col-xs-12">
								<Col sm="12">
									<WordMatrix
										watch={watch}
										setValue={setValue}
										setInvalidWordsData={setInvalidWordsData}
										setWordsMeaningSentences={setWordsMeaningSentences}
									/>
								</Col>
							</Col>
						</Row>
					</>
				);
			default:
				// content only questions

				if (uiType === UiTypeEnum.READING) {
					return (
						<>
							<Row>
								<Col sm="11">
									<InputNew name="title" label="Word to Read" defaultValue="" placeholder="Word to Read" required />
								</Col>
								<Col sm="1">
									<AudioPlayer audioData={audioData?.title?.[question?.voiceId]} text={question?.voiceId} />
								</Col>

								<Col sm="12">
									<DropzoneNew
										height="600"
										width="900"
										label="Associated Image: (Range of Aspect ratio 1.95 to 2.05)"
										name="descriptionImage"
									/>
								</Col>
							</Row>
						</>
					);
				}

				if (uiType === UiTypeEnum.VOCAB) {
					return (
						<>
							<Row>
								<Col sm="11">
									<InputNew name="title" label="Word" defaultValue="" placeholder="Question Text" required />
								</Col>
								<Col sm="1">
									<AudioPlayer audioData={audioData?.title?.[question?.voiceId]} text={question?.voiceId} />
								</Col>

								<Col sm="11">
									<TextAreaNew name="meaning" label="Meaning" defaultValue="" />
								</Col>
								<Col sm="1">
									<AudioPlayer audioData={audioData?.meaning?.[question?.voiceId]} text={question?.voiceId} />
								</Col>

								<Col sm="12">
									<SelectFieldNew
										label="Role Voice"
										name="roleVoiceId"
										options={roleVoiceOptions}
										defaultValue={roleVoiceOptions[0]}
										required
									/>
								</Col>
								<Col sm="12">
									<TextAreaNew name="partsOfSpeech" label="Parts of Speech" defaultValue="" />
								</Col>
								<Col>
									<DropzoneNew label="Associated Image: (Range of Aspect ratio 1.95 to 2.05)" name="descriptionImage" />
								</Col>
							</Row>
						</>
					);
				}

				return (
					<>
						<Row>
							<Col sm="11">
								<TextAreaNew name="title" label="Title" defaultValue="" />
							</Col>
							<Col sm="1">
								<AudioPlayer audioData={audioData?.title?.[question?.voiceId]} text={question?.voiceId} />
							</Col>
						</Row>
						<Row>
							<Box w="100%" d="flex" jc="space-between">
								{questionType?.value === QuestionTypesEnum.CONTENT_ONLY && (
									<Col sm="6">
										<InputNew label="Role Name" name="roleName" />
									</Col>
								)}
								<Col sm="6">
									<SelectFieldNew label="Role Voice" name="roleVoiceId" options={roleVoiceOptions} defaultValue={roleVoiceOptions[0]} />
								</Col>
							</Box>
						</Row>
						<Row>
							<Col>
								<DropzoneNew label="Description Image" name="descriptionImage" />
							</Col>
						</Row>
					</>
				);
		}
	};

	return (
		<Card>
			<LoadingOverlay isLoading={isLoading} />
			<CardBody>
				<Row className="mt-1 mb-4 rounded">
					<Col sm={12}>
						<Breadcrumbs breadcrumbs={breadcrumbsData.breadcrumbs} />
					</Col>
				</Row>
				<h3>{mode === 'edit' ? 'Question Edit' : 'Create Question'}</h3>
				<FormProvider {...{ ...hookFormMethods, register, handleSubmit, watch, errors, setValue, control }}>
					<form className="form ltr-support" onSubmit={handleSubmit(onSubmit)}>
						<Box w="100%" mt="1rem" mr="1rem" ml="0.5rem" mb="2rem">
							<Row>
								<Col sm={5} />
								<Col sm={7}>
									<h5>
										<b>Lesson : </b> {lesson?.info?.title?.en}
									</h5>
									<h5>
										<b>UI Type : </b> {lesson?.uiType}
									</h5>
									<h5>
										<b>Course :</b> {lesson?.course?.title?.en} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Admin Code :</b>{' '}
										{lesson?.course?.adminCode}
									</h5>
									<h5>
										<b>Topic :</b> {lesson?.topic?.title?.en} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Admin Code :</b>{' '}
										{lesson?.topic?.adminCode}
									</h5>
									<h5>
										<b>Subtopic :</b> {lesson?.subtopic?.title?.en} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Admin Code :</b>{' '}
										{lesson?.subtopic?.adminCode}
									</h5>
								</Col>
								<Col style={{ maxWidth: '250px' }}>
									<SelectFieldNew
										label="Select Question Type"
										name="type"
										options={QuestionTypeFields.filter((option) => uiType === UiTypeEnum.CROSSWORD_GAME || option.label !== 'Crossword')}
										defaultValue={QuestionTypeFields.find((option) => option.value === question?.type)}
										isDisabled={mode === 'edit'}
										required
									/>
								</Col>
							</Row>
							{getQuestionForm(({
								type: questionType?.value,
								uiType,
								voiceId: question?.voiceId,
								botVoiceId: (question as TConversationLessonQuestion)?.botVoiceId,
								systemVoiceId: (question as TConversationLessonQuestion)?.systemVoiceId,
							} as unknown) as TQuestion)}
						</Box>
						<Button type="submit" disabled={!areImagesValid}>
							{mode === 'edit' ? 'Update' : 'Add'}
						</Button>
					</form>
				</FormProvider>
			</CardBody>
		</Card>
	);
};

export default LessonQuestionAddEditForm;
